<template>
  <v-container
    id="prasemap-overview"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="primary"
          icon="mdi-graphql"
          title="Task Status"
          :value="taskStatus"
          sub-icon="mdi-graph"
          sub-text="-"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="primary"
          icon="mdi-sync"
          title="Iteration Number"
          :value="iterations"
          sub-icon="mdi-counter"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-clock"
          title="Last Run Time"
          :value="updateClock"
          sub-icon="mdi-clock-time-twelve"
          :sub-text="updateTime"
        >
        </base-material-stats-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-lan-pending"
          title="Awaiting Tasks"
          :value="waitingTasks"
          sub-icon="mdi-account-clock"
        ></base-material-stats-card>
      </v-col>
      <template
      v-for="(item, index) in statItems"
      >
      <v-col
        cols="12"
        md="6"
        :key="index"
      >
        <base-material-card
          :color="item.color"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              {{ item.title }}
            </div>
          </template>
          <v-card-text>
            <v-list dense>
              <v-list-item
              v-for="(stat, index) in item.stat"
              :key="index"
              >
                <v-list-item-content>
                  {{ stat.name }}
                </v-list-item-content>
                <v-spacer></v-spacer>
                <v-list-item-action class="align-end">
                  {{ stat.value }}
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
        </base-material-card>
      </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
  import api from '@/utils/api.js'
  export default {
    name: 'PRASEMap',
    data () {
      return {
        userId: null,
        taskInfo: null,
        statItems: [
          {
            title: 'KG1 Stats',
            color: 'primary',
            stat: [
              {
                name: 'Entity Number',
                value: '-',
              },
              {
                name: 'Relation Number',
                value: '-',
              },
              {
                name: 'Attribute Number',
                value: '-',
              },
              {
                name: 'Literal Number',
                value: '-',
              },
              {
                name: 'Relation Triple Number',
                value: '-',
              },
              {
                name: 'Attribute Triple Number',
                value: '-',
              },
            ],
          },
          {
            title: 'KG2 Stats',
            color: 'secondary',
            stat: [
              {
                name: 'Entity Number',
                value: '-',
              },
              {
                name: 'Relation Number',
                value: '-',
              },
              {
                name: 'Attribute Number',
                value: '-',
              },
              {
                name: 'Literal Number',
                value: '-',
              },
              {
                name: 'Relation Triple Number',
                value: '-',
              },
              {
                name: 'Attribute Triple Number',
                value: '-',
              },
            ],
          },
        ],
        taskStatus: '-',
        iterations: '-',
        createTime: '-',
        updateTime: '-',
        waitingTasks: '-',
        updateClock: '-',
      }
    },
    created () {
      this.userId = localStorage.getItem('taskId')
      this.createTime = localStorage.getItem('createTime') || '-'
      this.updateTime = localStorage.getItem('updateTime') || '-'
      this.updateClock = localStorage.getItem('updateClock') || '-'
      this.iterations = localStorage.getItem('iterations') || '-'
      this.waitingTasks = localStorage.getItem('waitingTasks') || '-'
      this.taskStatus = localStorage.getItem('taskStatus') || '-'
    },
    methods: {
      getTaskInfo () {
        api.getTaskInfo(this.userId).then((res) => {
          if (res.status === 200) {
            this.taskInfo = res.data.data
          }
        })
      },
      updateDataInfo () {
        api.getMappings(this.userId).then((res) => {
          if (res.status === 200) {
            if (res.data.success) {
              const kgInfoData = res.data.data.info
              if (kgInfoData) {
                const sourceInfo = []
                sourceInfo.push({
                  name: 'Entity Number',
                  value: kgInfoData.sourceKG.entNum,
                })
                sourceInfo.push({
                  name: 'Relation Number',
                  value: kgInfoData.sourceKG.relNum,
                })
                sourceInfo.push({
                  name: 'Attribute Number',
                  value: kgInfoData.sourceKG.attrNum,
                })
                sourceInfo.push({
                  name: 'Literal Number',
                  value: kgInfoData.sourceKG.liteNum,
                })
                sourceInfo.push({
                  name: 'Relation Triple Number',
                  value: kgInfoData.sourceKG.relTripleNum,
                })
                sourceInfo.push({
                  name: 'Attribute Triple Number',
                  value: kgInfoData.sourceKG.attrTripleNum,
                })
                const targetInfo = []
                targetInfo.push({
                  name: 'Entity Number',
                  value: kgInfoData.targetKG.entNum,
                })
                targetInfo.push({
                  name: 'Relation Number',
                  value: kgInfoData.targetKG.relNum,
                })
                targetInfo.push({
                  name: 'Attribute Number',
                  value: kgInfoData.targetKG.attrNum,
                })
                targetInfo.push({
                  name: 'Literal Number',
                  value: kgInfoData.targetKG.liteNum,
                })
                targetInfo.push({
                  name: 'Relation Triple Number',
                  value: kgInfoData.targetKG.relTripleNum,
                })
                targetInfo.push({
                  name: 'Attribute Triple Number',
                  value: kgInfoData.targetKG.attrTripleNum,
                })
                this.statItems[0].stat = sourceInfo
                this.statItems[1].stat = targetInfo
              }
            }
          }
        })
      },
    },
    mounted () {
      this.getTaskInfo()
      setInterval(this.getTaskInfo, 4000)
    },
    watch: {
      taskInfo: {
        handler (newVal, oldVal) {
          if (!oldVal || newVal.taskStatus !== oldVal.taskStatus || newVal.iteration !== oldVal.iteration) {
            this.createTime = newVal.createTime
            this.updateTime = newVal.updateTime || '-'
            if (this.updateTime !== '-') {
              this.updateClock = this.updateTime.split(' ')[1]
            }
            this.iterations = newVal.iteration || '-'
            this.waitingTasks = newVal.queueSize
            const statusCode = newVal.taskStatus
            switch (statusCode) {
              case null:
                this.taskStatus = 'Uninitialized'
                break
              case 0:
                this.taskStatus = 'Ready'
                break
              case 1:
                this.taskStatus = 'Waiting'
                break
              case 2:
                this.taskStatus = 'Running'
                break
              case 3:
                this.taskStatus = 'Failed'
                break
              case -1:
                this.taskStatus = 'Dead'
                break
              case -666:
                this.taskStatus = 'Destroyed'
                break
              default:
                this.taskStatus = 'Unknown'
                break
            }
            localStorage.setItem('createTime', this.createTime)
            localStorage.setItem('updateTime', this.updateTime)
            localStorage.setItem('updateClock', this.updateClock)
            localStorage.setItem('iterations', this.iterations)
            localStorage.setItem('waitingTasks', this.waitingTasks)
            localStorage.setItem('taskStatus', this.taskStatus)
            this.updateDataInfo()
          }
        },
      },
    },
  }
</script>

<style>
</style>
